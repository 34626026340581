.bootstrap-select {
  /*width: 220px\9; IE8 and below*/
  //noinspection CssShorthandPropertyValue
  width: 220px \0; /*IE9 and below*/

  & > .btn {
    width: 100%;
    padding-right: 25px;
  }

  .error & .btn {
    border: 1px solid #b94a48;
  }

  &.fit-width {
    width: auto !important;
  }
}

.bootstrap-select.btn-group:not(.input-group-btn),
.bootstrap-select.btn-group[class*="span"] {
  float: none;
  display: inline-block;
  margin-bottom: 10px;
  margin-left: 0;
}

.form-search .bootstrap-select.btn-group,
.form-inline .bootstrap-select.btn-group,
.form-horizontal .bootstrap-select.btn-group {
  margin-bottom: 0;
}

.bootstrap-select.form-control {
  margin-bottom: 0;
  padding: 0;
  border: none;
}

.bootstrap-select.btn-group.pull-right,
.bootstrap-select.btn-group[class*="span"].pull-right,
.row-fluid .bootstrap-select.btn-group[class*="span"].pull-right {
  float: right;
}

.bootstrap-select.btn-group {
  .input-append & {
    margin-left: -1px;
  }

  .input-prepend & {
    margin-right: -1px;
  }
}

.bootstrap-select:not([class*="span"]):not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
  width: 220px;
}

.bootstrap-select.form-control:not([class*="span"]) {
  width: 100%;
}

.bootstrap-select.show-menu-arrow.open > .btn {
  z-index: 2051;
}

.bootstrap-select .btn:focus {
  outline: thin dotted #333333 !important;
  outline: 5px auto -webkit-focus-ring-color !important;
  outline-offset: -2px;
}

.bootstrap-select.btn-group .btn .filter-option {
  display: inline-block;
  overflow: hidden;
  width: 100%;
  text-align: left;
}

.bootstrap-select.btn-group .btn .caret {
  position: absolute;
  top: 50%;
  right: 12px;
  margin-top: -2px;
  vertical-align: middle;
}

.bootstrap-select.btn-group > .disabled,
.bootstrap-select.btn-group .dropdown-menu li.disabled > a {
  cursor: not-allowed;
}

.bootstrap-select.btn-group > .disabled:focus {
  outline: none !important;
}

.bootstrap-select.btn-group[class*="span"] .btn {
  width: 100%;
}

.bootstrap-select.btn-group .dropdown-menu {
  min-width: 100%;
  z-index: 2000;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.bootstrap-select.btn-group .dropdown-menu.inner {
  position: static;
  border: 0;
  padding: 0;
  margin: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.bootstrap-select.btn-group .dropdown-menu dt {
  display: block;
  padding: 3px 20px;
  cursor: default;
}

.bootstrap-select.btn-group .div-contain {
  overflow: hidden;
}

.bootstrap-select.btn-group .dropdown-menu li {
  position: relative;
}

.bootstrap-select.btn-group .dropdown-menu li > a.opt {
  position: relative;
  padding-left: 35px;
}

.bootstrap-select.btn-group .dropdown-menu li > a {
  cursor: pointer;
}

.bootstrap-select.btn-group .dropdown-menu li > dt small {
  font-weight: normal;
}

.bootstrap-select.btn-group.show-tick .dropdown-menu li.selected a i.check-mark {
  position: absolute;
  display: inline-block;
  right: 15px;
  margin-top: 2.5px;
}

.bootstrap-select.btn-group .dropdown-menu li a i.check-mark {
  display: none;
}

.bootstrap-select.btn-group .dropdown-menu li a span.text {
  display: inline-block;
}

.bootstrap-select.btn-group.show-tick .dropdown-menu li a span.text {
  margin-right: 34px;
}

.bootstrap-select.btn-group .dropdown-menu li small {
  padding-left: 0.5em;
}

.bootstrap-select.btn-group .dropdown-menu {
  & li:not(.disabled) > a:hover small,
  & li:not(.disabled) > a:focus small,
  & li.active:not(.disabled) > a small {
    color: rgb(100, 177, 216);
    color: rgba(100, 177, 216, 0.4);
  }

  & li > dt small {
    font-weight: normal;
  }
}

.bootstrap-select.show-menu-arrow {
  .dropdown-toggle {
    &:before {
      content: '';
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-bottom-width: 7px;
      border-bottom-style: solid;
      border-bottom-color: rgb(204, 204, 204);
      border-bottom-color: rgba(204, 204, 204, 0.2);
      position: absolute;
      bottom: -4px;
      left: 9px;
      display: none;
    }

    &:after {
      content: '';
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 6px solid white;
      position: absolute;
      bottom: -4px;
      left: 10px;
      display: none;
    }
  }

  &.dropup {
    .dropdown-toggle {
      &:before {
        bottom: auto;
        top: -3px;
        border-bottom: 0;
        border-top-width: 7px;
        border-top-style: solid;
        border-top-color: rgb(204, 204, 204);
        border-top-color: rgba(204, 204, 204, 0.2);
      }

      &:after {
        bottom: auto;
        top: -3px;
        border-top: 6px solid #FFFFFF;
        border-bottom: 0;
      }
    }
  }

  &.pull-right {
    .dropdown-toggle {
      &:before {
        right: 12px;
        left: auto;
      }

      &:after {
        right: 13px;
        left: auto;
      }
    }
  }

  &.open > .dropdown-toggle {
    &:before,
    &:after {
      display: block;
    }
  }
}

.bootstrap-select.btn-group .no-results {
  padding: 3px;
  background: #f5f5f5;
  margin: 0 5px;
}

.bootstrap-select.btn-group .dropdown-menu .notify {
  position: absolute;
  bottom: 5px;
  width: 96%;
  margin: 0 2%;
  min-height: 26px;
  padding: 3px 5px;
  background: #f5f5f5;
  border: 1px solid #e3e3e3;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  pointer-events: none;
  opacity: 0.9;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.bootstrap-select.btn-group.fit-width .btn .filter-option {
  position: static;
}

.bootstrap-select.btn-group.fit-width .btn .caret {
  position: static;
  top: auto;
  margin-top: -1px;
}

.control-group.error .bootstrap-select .dropdown-toggle {
  border-color: #b94a48;
}

.bootstrap-select-searchbox,
.bootstrap-select .bs-actionsbox {
  padding: 4px 8px;
}

.bootstrap-select .bs-actionsbox {
  float: left;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  & .btn-group button {
    width: 50%;
  }
}

.bootstrap-select-searchbox {
  & + .bs-actionsbox {
    padding: 0 8px 4px;
  }

  & input, & .form-control {
    margin-bottom: 0;
    width: 100%;
  }
}

.mobile-device {
  position: absolute;
  top: 0;
  left: 0;
  display: block !important;
  width: 100%;
  height: 100% !important;
  opacity: 0;
}
